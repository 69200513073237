import { colors, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#FAFAFA",
    paper: colors.common.white,
  },
  primary: {
    main: "#7388A6",
  },
  secondary: {
    main: "#FF844B",
    contrastText: "#FAFAFA",
  },
  error: {
    main: "#FF844B",
  },
  action: {
    active: "#FF844B",
  },
  text: {
    primary: "#04121E",
    secondary: "#6E6E73",
  },
};

export default palette;
